import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useKeenSlider} from "keen-slider/react";
import 'keen-slider/keen-slider.min.css';
import {Section} from "../../../common/section";
import GatsbyImage from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import {ArrowLeft, ArrowRight, MiddleText} from "../../../common/carousel/carousel";

export const Applications: React.FC = () => {
  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  const [pause, setPause] = useState(false);
  const timer = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideRef, slider] = useKeenSlider({
    initial: 0,
    loop: true,
    duration: 2000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    }
  });

  useEffect(() => {
    slideRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    slideRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [slideRef])

  useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 3000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  const [markdownText, setMarkdownText] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {

    const fetchMarkdown = async (): Promise<void> => {
      const markdownFile1 = await import(
          `./applications/applications_1.${currentLanguage}.md`
          );
      const markdownFile2 = await import(
          `./applications/applications_2.${currentLanguage}.md`
          );
      const markdownFile3 = await import(
          `./applications/applications_3.${currentLanguage}.md`
          );
      const markdownFile4 = await import(
          `./applications/applications_4.${currentLanguage}.md`
          );
      const markdownFile5 = await import(
        `./applications/applications_5.${currentLanguage}.md`
        );
      setMarkdownText([markdownFile1, markdownFile2, markdownFile3, markdownFile4, markdownFile5]);
      setIsLoading(false);
    };
    fetchMarkdown();
  }, [currentLanguage]);

  const data = useStaticQuery(graphql`
  query {
  room: file(relativePath: {eq: "carousel/waiting-room.jpg"}) { ...carouselImage }
  convent: file(relativePath: {eq: "carousel/wedding-accessories-interior-saint-trinity-orthodox-convent.jpg"}) { ...carouselImage }
  stadium: file(relativePath: {eq: "carousel/markus-spiske-J_tbkGWxCH0-unsplash.jpg"}) { ...carouselImage }
  airport: file(relativePath: {eq: "carousel/airplane-flying-past-departures-lounge-window.jpg"}) { ...carouselImage }
  gym: file(relativePath: {eq: "carousel/gym-equipments-fitness-club.jpg"}) { ...carouselImage }  
  }`)

  return (
      <Section className="carousel">
        <p className="subheading">{t('products.skeptron.hybrid.applications.subheading')}</p>

        <div className="navigation-wrapper">
          <div ref={slideRef} className="keen-slider">
            <div className="keen-slider__slide">

            <div className="slider-text">
              {markdownText[0] ?
                  <div dangerouslySetInnerHTML={{__html: markdownText[0].html}}/> : null
              }
              {/* TODO: add css flex wrapper */}
              </div>
              <div className="slider-image">
                <GatsbyImage
                  style={{height: "100%", width: "100%"}}
                  fluid={data.room.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="keen-slider__slide">
            <div className="slider-text">
              {markdownText[1] ?
                  <div dangerouslySetInnerHTML={{__html: markdownText[1].html}}/> : null
              }
              {/* TODO: add css flex wrapper */}
              </div>
              <div className="slider-image">
                <GatsbyImage
                  style={{height: "100%", width: "100%"}}
                  fluid={data.convent.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="keen-slider__slide">
            <div className="slider-text">
              {markdownText[2] ?
                  <div dangerouslySetInnerHTML={{__html: markdownText[2].html}}/> : null
              }
              {/* TODO: add css flex wrapper */}
              </div>
              <div className="slider-image">
                <GatsbyImage
                  style={{height: "100%", width: "100%"}}
                  fluid={data.stadium.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="keen-slider__slide">
            <div className="slider-text">
              {markdownText[3] ?
                  <div dangerouslySetInnerHTML={{__html: markdownText[3].html}}/> : null
              }
              {/* TODO: add css flex wrapper */}
              </div>
              <div className="slider-image">
                <GatsbyImage
                  style={{height: "100%", width: "100%"}}
                  fluid={data.airport.childImageSharp.fluid}
                />
              </div>
            </div>
            <div className="keen-slider__slide">
            <div className="slider-text">
              {markdownText[4] ?
                <div dangerouslySetInnerHTML={{__html: markdownText[4].html}}/> : null
              }
              {/* TODO: add css flex wrapper */}
              </div>
              <div className="slider-image">
                <GatsbyImage
                  style={{height: "100%", width: "100%"}}
                  fluid={data.gym.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </div>
        {slider && (
          <>
            <ArrowLeft
              onClick={(e) => e.stopPropagation() || slider.prev()}
              disabled={currentSlide === 0}
            />
            <MiddleText
              currentSlide={currentSlide}
              slider={slider}
            />
            <ArrowRight
              onClick={(e) => e.stopPropagation() || slider.next()}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
      </Section>
  )
}
