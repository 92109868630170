import React from "react";
import {NormalLayout} from "../../../components/layouts/normal_layout";
import {Introduction} from "../../../components/products/skeptron/hybrid/introduction";
import {Benefits} from "../../../components/products/skeptron/hybrid/benefits";
import {Details} from "../../../components/products/skeptron/hybrid/details";
import {Applications} from "../../../components/products/skeptron/hybrid/applications";
import {Specifications} from "../../../components/products/skeptron/hybrid/specifications";
import {SEO} from "../../../components/seo";

const Hybrid: React.FC = () => {

  return (
    <NormalLayout type="product">
      <SEO title="Skeptron Hybrid | UV Tech by Rendev" description="Skeptron Hybrid | UV Tech by Rendev" />
      <Introduction />
      <Benefits />
      <Details />
      <Applications />
      <Specifications />
    </NormalLayout>
  )
}

export default Hybrid;