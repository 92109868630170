import React from "react";
import {useTranslation} from "react-i18next";
import {Separator} from "../../../common/separator";
import {Section} from "../../../common/section";

export const Details: React.FC = () => {
  const {t} = useTranslation();

  const items = [1,2,3,4];

  return (
      <Section className="details">
        <h2>{t('products.skeptron.hybrid.details.heading')}</h2>
        <p>{t('products.skeptron.hybrid.details.description')}</p>
        <div className="details-list">
          {
            items.map((item, index) => {
              return (
                  <div key={index}>
                    <h1>{index+1}</h1>
                    <h3>{t(`products.skeptron.hybrid.details.items.${index}.heading`)}</h3>
                   
                    <p className="small">{t(`products.skeptron.hybrid.details.items.${index}.description`)}</p>
                  </div>
              )
            })
          }
        </div>
      </Section>
  )
}
